import { getDefaultStore } from "jotai";
import { convertToHex8 } from "../colors";
import { atoms } from "../helpers/atoms";
import {
  EndSketchNotificationContent,
  SketchBackgroundNotificationContent,
  SketchDrawingNotificationContent,
  SketchImageNotificationContent,
  SketchInvitationNotificationContent,
  SketchUndoNotificationContent,
} from "../helpers/notificationChannel";
import WebGwContact from "../helpers/WebGwContact";
import { sendDelete, sendMessage } from "../messaging";

const defaultStore = getDefaultStore();

export const sendSketch = async (
  contacts: (WebGwContact | undefined)[],
  sketch: any,
  conferenceUri = ""
) => {
  await send(contacts, JSON.stringify(sketch), conferenceUri);
};

export const sendSketchInvite = async (
  contacts: (WebGwContact | undefined)[],
  id: number,
  conferenceUri = ""
) => {
  await send(contacts, `{"version" : {"id": ${id}}}`, conferenceUri);
};

export const endSketch = async (
  contacts: (WebGwContact | undefined)[],
  conferenceUri = ""
) => {
  if (conferenceUri.length > 0 || contacts.length > 1) return;
  await send(contacts, '{"close" : ""}');
};

const send = async (
  contacts: (WebGwContact | undefined)[],
  payload: string,
  conferenceUri = ""
) => {
  const isGroupChat = conferenceUri.length > 0 || contacts.length > 1;
  const to = conferenceUri
    ? conferenceUri
    : contacts.map((p) => "tel:+1" + p?.getMainPhoneNumber()).join(",");
  await sendMessage({
    to,
    isGroupChat,
    conferenceUri,
    chatMessage: {
      chatMessage: {
        text: payload,
        contentType: "application/vnd.gsma.sharedsketch+xml",
        reportRequest: [],
      },
    },
  });
};

export const sendSketchBye = async (
  contacts: (WebGwContact | undefined)[],
  conferenceUri = ""
) => {
  if (conferenceUri.length > 0 || contacts.length > 1) return;
  await sendDelete("sketch", contacts[0]?.getMainPhoneNumber());
};

export const acceptSketchInvitation = async (
  phoneNumber: (WebGwContact | undefined)[],
  id: number,
  conferenceUri = ""
) => {
  // Same payload as sending the invitation
  await sendSketchInvite(phoneNumber, id, conferenceUri);
};

export const rejectSketchInvitation = async (
  contacts: (WebGwContact | undefined)[]
) => {
  // Same payload as ending the invitation
  await endSketch(contacts);
  sendSketchBye(contacts);
};

export function handleSketchInvitation(
  sketchInvitationNotification: SketchInvitationNotificationContent
) {
  defaultStore.set(atoms.sketch.incomingSketchInfos, {
    conferenceUri: sketchInvitationNotification.callid,
    number: sketchInvitationNotification.sender,
    startFullScreen: true,
    ...sketchInvitationNotification.sketch.version,
  });
}

export function handleEndSketch(
  endSketchNotification: EndSketchNotificationContent
) {
  const code = endSketchNotification.sketch.close;
  defaultStore.set(atoms.sketch.incomingSketchEndedInfos, {
    number: endSketchNotification.sender,
    id: code == "" ? 0 : code,
  });
}

export function handleSketchDrawing(
  sketchDrawingNotification: SketchDrawingNotificationContent
) {
  sketchDrawingNotification.sketch.drawing.color = convertColorForSketch(
    sketchDrawingNotification.sketch.drawing.color,
    false
  );
  defaultStore.set(
    atoms.sketch.incomingSketchDrawing,
    sketchDrawingNotification.sketch
  );
}

export function handleSketchUndo(
  sketchUndoNotification: SketchUndoNotificationContent
) {
  defaultStore.set(
    atoms.sketch.incomingSketchUndo,
    sketchUndoNotification.sketch
  );
}

export function handleSketchBackground(
  sketchBackgroundNotification: SketchBackgroundNotificationContent
) {
  sketchBackgroundNotification.sketch.background_color.color =
    convertColorForSketch(
      sketchBackgroundNotification.sketch.background_color.color,
      false
    );

  defaultStore.set(
    atoms.sketch.incomingSketchBackground,
    sketchBackgroundNotification.sketch
  );
}

export function handleSketchImage(
  sketchImageNotification: SketchImageNotificationContent
) {
  defaultStore.set(
    atoms.sketch.incomingSketchImage,
    sketchImageNotification.sketch
  );
}

// Sketch server color format is hex8 special format with opacity first instead of end, without the #, this method converts it for sending / receiving
export const convertColorForSketch = (color: string, outgoing: boolean) => {
  // Outgoing, we put the alpha at the beginning and remove the trailing #
  if (outgoing) {
    const hex8 = convertToHex8(color);
    return `${hex8.substring(7)}${color.substring(1, 7)}`;
  }
  // Incoming, we put the alpha t the end with trailing #
  else {
    return `#${color.substring(2)}${color.substring(0, 2)}`;
  }
};
