import { ServiceCapabilityArray } from "@/types/capabilities";
import { baseWebGwUrl, sleep } from "..";
import { OmaNmsSchema } from "../../types/OmaNms";
import type NmsMessage from "../messaging/NmsMessage";
import { getLocalAccessToken, getLocalUser } from "./localstorage";
import { publishCaps } from "./loginAndCaps/publishCaps";

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type CapabilityNotification = {
  contactServiceCapabilities: {
    resourceURL: string;
    serviceCapability?: ServiceCapabilityArray;
    uri: string;
    userType?: string | undefined;
  };
};

export type NewMessageNotification = {
  chatMessage: {
    contentType: string; // "text/plain"
    referenceType?: NmsMessage["Reference-Type"];
    referenceId?: string;
    reportRequest: "Delivered" | "Displayed" | string;
    resourceURL: string; // "https://sbc.erl.rcs.st:8443/chat/v1/tel:+15145550081/oneToOne/tel:+15145550080/adhoc/messages/CH647fb3ef613cac70"
    text: string;
  };
  dateTime: string; // Date
  link: [
    {
      href: string; // "https://sbc.erl.rcs.st:8443/chat/v1/tel:+15145550081/oneToOne/tel:+15145550080/adhoc/messages/CH647fb3ef613cac70"
      rel: "ChatMessage"; // TODO find other possible values
    },
  ];
  senderAddress: string; // "tel:+15145550081"
  senderName: string; // "+15145550081"
};

export type NewGroupChatInvitationNotification = {
  group_id: string;
  invite_received: string; // list of participants separated by commas
  subject: string;
  icon: string;
  admin: string;
};

export type GroupChatIconNotification = {
  group_id: string;
  set_icon: string; // participant that setted the icon
  icon: string;
};

export type SketchNotification = {
  callid?: string;
  sender: string;
  sketch: any;
};

export type SketchInvitationNotificationContent = {
  sketch: {
    version: SketchIdType;
  };
} & SketchNotification;

export type EndSketchNotificationContent = {
  sketch: {
    close: string;
  };
} & SketchNotification;

export type SketchDrawingNotificationContent = {
  sketch: SketchDrawingType;
} & SketchNotification;

export type SketchUndoNotificationContent = {
  sketch: SketchUndoType;
} & SketchNotification;

export type SketchBackgroundNotificationContent = {
  sketch: SketchBackgroundType;
} & SketchNotification;

export type SketchImageNotificationContent = {
  sketch: SketchImageType;
} & SketchNotification;

export type SketchIdType = {
  id: number;
};

export type SketchBackgroundType = {
  background_color: {
    color: string;
  } & SketchIdType;
};

export type SketchImageType = {
  image: {
    base64: string;
  } & SketchIdType;
};

export type SketchDrawingType = {
  drawing: {
    color: string;
    erase?: string;
    width: string;
    points: number[];
  } & SketchIdType;
};

export type SketchUndoType = {
  undo: SketchIdType;
};

export type ComposingNotification = {
  dateTime: string; // Date
  isComposing: {
    refresh: number; // 60
    state: "active" | "idle";
  };
  link?: [{ href: string; rel: string }];
  senderAddress: string; // "tel:+15145550081"
  senderName: string; // "+15145550081"
};

export type ChatMessageNotification = {
  chatMessageNotification: NewMessageNotification | ComposingNotification;
};

export type GroupChatInvitationNotification = {
  groupchatNotification: NewGroupChatInvitationNotification;
};

export type GroupChatSetIconNotification = {
  groupchatNotification: GroupChatIconNotification;
};

export type SketchInvitationNotification = {
  sketchNotification: SketchInvitationNotificationContent;
};

export type EndSketchNotification = {
  sketchNotification: EndSketchNotificationContent;
};

export type SketchDrawingNotification = {
  sketchNotification: SketchDrawingNotificationContent;
};

export type SketchUndoNotification = {
  sketchNotification: SketchUndoNotificationContent;
};

export type SketchBackgroundNotification = {
  sketchNotification: SketchBackgroundNotificationContent;
};

export type SketchImageNotification = {
  sketchNotification: SketchImageNotificationContent;
};

export type ImdnStatusNotification = {
  link: [
    {
      href: string; // "https://sbc.erl.rcs.st:8443/chat/v1/sip:+15145550081@erl.rcs.st/oneToOne/sip:+15145550080@erl.rcs.st/adhoc/messages/hdjshu5jagu4e5x6";
      rel: "ChatMessage";
    },
  ];
  status:
    | "stored" /* server received the message */
    | "Delivered" /* remote party received the message */
    | "Displayed" /* remote party read the message */; // TODO find other possible values
};

export type ChatMessageStatusNotification = {
  chatMessageStatusNotification: ImdnStatusNotification;
};

export type ClientStateNotification = {
  clientStateNotification: {
    state_str: string; // "Registered"
    state: number; // 256
  };
};

export type WebRTCPauseNotification = {
  callid: string;
  method: string;
  pause: boolean;
};

export type WebRTCRingingNotification = {
  callid: string;
  method: string;
  calluri: string;
};

export type WebRTCStatusUpdateNotification = {
  callid: string;
  calluri: string;
  method: string;
  reason: number | undefined;
};

export type WebRTCAnswerNotification = {
  callid: string;
  calluri: string;
  method: string;
  sdp: string;
};

export type WebRTCCVONotification = {
  calluri: string;
  cvocode: number;
  method: string;
};

export type WebRTCMessageNotification = {
  summitCallNotification:
    | WebRTCPauseNotification
    | WebRTCStatusUpdateNotification
    | WebRTCAnswerNotification
    | WebRTCCVONotification;
};

export type Notification =
  | CapabilityNotification
  | ChatMessageNotification
  | WebRTCMessageNotification
  | GroupChatInvitationNotification
  | GroupChatSetIconNotification
  | SketchInvitationNotification
  | EndSketchNotification
  | SketchDrawingNotification
  | SketchUndoNotification
  | SketchBackgroundNotification
  | SketchImageNotification;

type NotificationRes =
  | {
      notificationList: Notification[];
    }
  | {
      notificationList: ClientStateNotification;
    }
  | {
      notificationList: Notification;
    }
  | ChatMessageStatusNotification
  | GroupChatInvitationNotification
  | GroupChatIconNotification
  | CapabilityNotification
  | SketchInvitationNotification
  | EndSketchNotification
  | SketchDrawingNotification
  | SketchUndoNotification
  | SketchBackgroundNotification
  | SketchImageNotification;

export const registerListenerType = "register";
export class RegisterEvent extends CustomEvent<boolean> {
  constructor(registered: boolean) {
    super(registerListenerType, {
      detail: registered,
    });
  }
}

export const notificationListenerType = "notification";
export class NotificationEvent extends CustomEvent<NotificationRes> {
  constructor(
    eventInitDict: WithRequired<CustomEventInit<NotificationRes>, "detail">
  ) {
    super(notificationListenerType, eventInitDict);
  }
}

export const nmsListenerType = "nmsObject";
export class NmsEventNotification extends CustomEvent<OmaNmsSchema> {
  constructor(
    nmsInitDict: WithRequired<CustomEventInit<OmaNmsSchema>, "detail">
  ) {
    super(nmsListenerType, nmsInitDict);
  }
}
export type NotificationListener = (event: NotificationEvent) => void;
export type RegisterListener = (event: RegisterEvent) => void;
export type NmsListener = (event: NmsEventNotification) => void;

interface NotificationChannel extends EventTarget {
  addEventListener(
    type: typeof notificationListenerType,
    // callback: EventListener | null,
    callback: NotificationListener | null,
    // callback: EventListenerOrEventListenerObject | null,
    options?: boolean | AddEventListenerOptions | undefined
  ): void;
  dispatchEvent(evt: NotificationEvent): boolean;
  removeEventListener(
    type: typeof notificationListenerType,
    callback: NotificationListener | null,
    options?: EventListenerOptions | boolean
  ): void;

  addEventListener(
    type: typeof registerListenerType,
    callback: RegisterListener | null,
    options?: boolean | AddEventListenerOptions | undefined
  ): void;
  dispatchEvent(evt: NotificationEvent): boolean;
  removeEventListener(
    type: typeof registerListenerType,
    callback: RegisterListener | null,
    options?: EventListenerOptions | boolean
  ): void;

  addEventListener(
    type: typeof registerListenerType,
    callback: EventListener | null,
    options?: boolean | AddEventListenerOptions | undefined
  ): void;
  dispatchEvent(evt: RegisterEvent): boolean;
  removeEventListener(
    type: typeof registerListenerType,
    callback: EventListener | null,
    options?: EventListenerOptions | boolean
  ): void;

  addEventListener(
    type: typeof nmsListenerType,
    callback: NmsListener | null,
    options?: boolean | AddEventListenerOptions | undefined
  ): void;
  dispatchEvent(evt: NmsEventNotification): boolean;
  removeEventListener(
    type: typeof nmsListenerType,
    callback: NmsListener | null,
    options?: EventListenerOptions | boolean
  ): void;
}
class NotificationChannel extends EventTarget {}

export const notificationChannel = new NotificationChannel();

export class NotificationChannelManager {
  private evtSource: EventSource | null = null;

  public close() {
    this.evtSource?.close();
  }

  public async subscribe(
    accessToken: string,
    user: string,
    callbackURL: string,
    resourceURL?: string
  ) {
    let url = "";
    if (callbackURL?.length) {
      url = callbackURL + `?access_token=${accessToken}`;
    } else if (user?.length && accessToken?.length) {
      console.debug("Construct own URL using user + accessToken");
      url = `${baseWebGwUrl}/notificationchannel/v1/${user}/channels?access_token=${accessToken}`;
    } else {
      console.error("No values set... Cannot build URL");
      return;
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        // referrerPolicy: "no-referrer-when-downgrade",
        body: JSON.stringify({
          notificationChannel: {
            clientCorrelator: `cl${user}`,
            applicationTag: "webclient",
            channelType: "LongPolling",
            channelData: {
              maxNotifications: 3,
              maxWaitTime: 60,
              type: "LongPollingData",
            },
            channelLifetime: 3600,
          },
        }),
        // "notificationChannel":{"applicationTag":"joynWebClient","channelData":{"maxNotifications":"2","type":"nc:LongPollingData"},"channelLifetime":"7200","channelType":"LongPolling","clientCorrelator":"cl1573053308242"}}
      });

      switch (response.status) {
        case 502: {
          // let's reconnect // and the remote server or a proxy closed it // may happen when the connection was pending for too long, // Status 502 is a connection timeout error,
          await this.subscribe(accessToken, user, callbackURL);
          break;
        }
        case 403: {
          console.log(
            "Couldn't establish a connection with the webgw, probably due to bad format"
          );
          user = "";
          accessToken = "";
          notificationChannel.dispatchEvent(new RegisterEvent(false));
          return;
        }
        case 404: {
          console.log("Received code 404 Not Found, retrying fresh connection");

          const response = await fetch(
            resourceURL + `?access_token=${accessToken}`,
            {
              method: "DELETE",
            }
          );
          const message = await response.text();
          console.log(message);
          callbackURL = "";
          console.log("404 CALLBACKURL", callbackURL);
          console.log("Waiting 1s before retrying fresh connection");
          await sleep(1000);
          await this.subscribe(accessToken, user, callbackURL);
          break;
        }
        case 201: {
          const text = await response.text();
          if (text) {
            const parsedNotificationChannel = JSON.parse(text); // JSON.parse the text instead of response.json() so an error isn't thrown
            const callbackURL =
              parsedNotificationChannel?.notificationChannel?.callbackURL;
            const timeout =
              parsedNotificationChannel?.notificationChannel?.channelLifetime;
            const newResourceURL =
              parsedNotificationChannel?.notificationChannel?.resourceURL;
            console.log(
              `received notification channel creation, now using ${callbackURL}`
            );
            this.startNotificationChannel();
            notificationChannel.dispatchEvent(new RegisterEvent(true));
          } else {
            //TODO: Add handling of a failure to create a notification channel
          }
          break;
        }
        case 400:
          console.log("Received code 400 Not Found, retrying fresh connection");
          break;
      }
    } catch (e: any) {
      console.error("Caught:", e);
      notificationChannel.dispatchEvent(new RegisterEvent(false));
    }
  }

  private startNotificationChannel() {
    console.warn("Starting Notification Channel");
    if (this.evtSource) {
      console.info("evtSource already created");
      return;
    }

    const accessToken = getLocalAccessToken();
    const user = getLocalUser();
    if (!accessToken || !user) return;

    this.evtSource = new EventSource(
      new URL(`/event/v1/${user}?access_token=${accessToken}`, baseWebGwUrl),
      {
        withCredentials: false,
      }
    );

    this.evtSource.onopen = () => {
      console.debug("Opened notification channel");
      publishCaps();
    };

    // TODO - This will be triggered from webgw in case of incoming sketch image too big, probably crashing the socket instance server side. Check with backend team to prevent this as we have no clue it is the root cause and cannot take action.
    this.evtSource.onerror = (e) => {
      console.error("EventSource error event", e);
      this.evtSource?.close();
      notificationChannel.dispatchEvent(new RegisterEvent(false));
      this.evtSource = null;
    };

    this.evtSource.onmessage = (e) => {
      let parsedMessage: NotificationRes;
      if (!e.data || e.data.length === 0) return;
      try {
        parsedMessage = JSON.parse(e.data);
      } catch (err) {
        console.warn("Unable to parse EventSource message", e.data, err);
        return;
      }
      console.debug("FROM SSE:", parsedMessage);
      notificationChannel.dispatchEvent(
        new NotificationEvent({
          detail: parsedMessage,
        })
      );
    };
  }
}
