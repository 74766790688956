import VoiceBotIcon from "@/assets/voicebot-ico.png";
import { colors } from "@/styles/global.styles";
import { ease } from "@/utils/ease";
import { useToast } from "@/utils/helpers/toastManager";
import { getWebRTC } from "@/utils/webrtc/webrtcUtils";
import { keyframes } from "@emotion/react";
import Color from "color";
import { AnimatePresence, motion } from "framer-motion";
import React, { Suspense, useEffect, useLayoutEffect, useState } from "react";
import { ButtonBar, CallOverlayProps } from ".";
import WebGwContact from "../../utils/helpers/WebGwContact";
import { Avatar } from "../shared/Avatar";
import {
  DraggableOverlay,
  overlayTransition,
} from "../shared/DraggableOverlay";
import Timer from "../shared/Timer";
import { CloseOverlayButtonBox } from "./Overlay";
import "./VoiceOverlay.css";

const VoiceBotOverlay = React.lazy(() => import("./VoiceBotOverlay"));

function OnHoldIndicator({ onHold }: { onHold: boolean }) {
  return onHold ? (
    <motion.div
      css={{
        top: "calc(100% + 0.5em)",
        width: "max-content",
        zIndex: 1,
        borderRadius: "10em",
        padding: "0.8em",
        backgroundColor: new Color(colors.secondaryAccentColor)
          .alpha(0.6)
          .toString(),
        // pulse background color
        animation: `${keyframes({
          "50%": {
            backgroundColor: new Color(colors.secondaryAccentColor)
              .alpha(0.4)
              .toString(),
          },
        })} 2s ease-in-out infinite`,
      }}
      initial={{ opacity: 0 }}
      animate={onHold ? { opacity: 1 } : { opacity: 0 }}
      exit={{ opacity: 0, transition: overlayTransition }}
      transition={{ duration: 0.75, ease: ease }}
    >
      Your call is currently on hold...
    </motion.div>
  ) : (
    <div />
  );
}

function VoiceContactAvatar({ contact }: { contact: WebGwContact }) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "2em",
        paddingTop: "2vh",
      }}
    >
      <Avatar
        contact={contact}
        style={{ width: "13vw", height: "13vw", fontSize: "6vw" }}
      />
      <div
        css={{
          margin: "0",
          whiteSpace: "nowrap",
          fontSize: "2.1875em",
        }}
      >
        {contact.noNameReturnPhoneNumber()}
      </div>
    </div>
  );
}

function CallingAnimation() {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const timerId = setInterval(() => {
      setDots((dots) => {
        switch (dots) {
          case "":
            return ".";
          case ".":
            return "..";
          case "..":
            return "...";
          default:
            return "";
        }
      });
    }, 500);

    return () => clearInterval(timerId);
  }, []);

  return <p>Calling Mobile{dots}</p>;
}

export function VoiceCallOverlay({
  open,
  isFullScreen,
  toggleFullScreen,
  callActive,
  callMuted,
  contact,
  muteCall,
  endCall,
  time,
  showNotImplemented,
  isPaused,
}: CallOverlayProps) {
  const DEFAULT_VOICE_BOT_TOP = "69%";
  const [voiceBotActive, setVoiceBotActive] = useState(false);
  const [setTop, changeSetTop] = useState(DEFAULT_VOICE_BOT_TOP);
  const [isHovered, setIsHovered] = useState(false);
  const [transcribedText, setTranscribedText] = useState("");
  const { showToast } = useToast();
  useLayoutEffect(() => {
    setIsHovered(false);
  }, [isFullScreen]);

  useEffect(() => {
    resetVoiceBot();
    if (callActive) {
      getWebRTC()
        ?.getVoiceBotManager()
        .setTranscriptCallback(onIncomingTranscriptReceived);
    }
  }, [callActive]);

  useEffect(() => {
    if (voiceBotActive) {
      console.log("Starting voice bot");
      if (
        !getWebRTC()?.startLiveTranscription() ||
        !getWebRTC()?.startCallTranscription()
      ) {
        setVoiceBotActive(false);
        changeSetTop(DEFAULT_VOICE_BOT_TOP);
        showToast("Could not start VoiceBot");
      }
    } else {
      console.log("Stopping voice bot");
      getWebRTC()?.stopLiveTranscription();
      getWebRTC()?.stopCallTranscription();
      setTranscribedText("");
    }
  }, [voiceBotActive]);

  if (!contact) return null;

  function onIncomingTranscriptReceived(text: string) {
    setTranscribedText(text);
  }

  function handleVoiceBotClick() {
    setTranscribedText("");
    if (setTop === DEFAULT_VOICE_BOT_TOP) {
      changeSetTop("8%");
    } else {
      changeSetTop(DEFAULT_VOICE_BOT_TOP);
    }
    setVoiceBotActive(!voiceBotActive);
  }

  function resetVoiceBot() {
    changeSetTop(DEFAULT_VOICE_BOT_TOP);
    setVoiceBotActive(false);
  }

  return (
    <AnimatePresence>
      {contact && (
        <DraggableOverlay
          open={open}
          isFullScreen={isFullScreen}
          onDoubleClick={() => {
            if (!isFullScreen) toggleFullScreen?.();
          }}
          onBackgroundDoubleClick={toggleFullScreen}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          component="call"
        >
          <CloseOverlayButtonBox
            isFullScreen={isFullScreen}
            isHovered={isHovered}
            toggleFullScreen={toggleFullScreen}
          />
          {!isFullScreen ? (
            <>
              <motion.div
                css={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  padding: "0.5em",
                }}
                initial={{ opacity: 0.5 }}
                animate={{ opacity: isHovered ? 1 : 0.5 }}
              >
                <ButtonBar
                  callActive={callActive}
                  callMuted={callMuted}
                  muteCall={muteCall}
                  endCall={endCall}
                  showNotImplemented={showNotImplemented}
                  isFullScreen={isFullScreen}
                />
              </motion.div>

              {callActive && voiceBotActive && (
                <motion.button
                  className="voice-bot-button"
                  css={{
                    position: "fixed",
                    cursor: callActive ? "pointer" : "not-allowed",
                    bottom: 0,
                    right: 0,
                  }}
                  disabled={!callActive}
                >
                  <img src={VoiceBotIcon} alt="Active Voice Bot" />
                </motion.button>
              )}
              <div
                css={{
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px #00000029",
                  width: "12em",
                  height: "12em",
                  backgroundColor: colors.primaryBackgroundLighter,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Avatar
                  contact={contact}
                  style={
                    contact.photo || !contact.initials
                      ? { width: "5.5em", height: "5.5em" }
                      : { width: "2.5em", height: "2.5em", fontSize: "4em" }
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  css={{
                    color: colors.primaryTextColor,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "2em",
                    width: "35em",
                    maxWidth: "90vw",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      paddingTop: "40px",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "0.75em",
                    }}
                  >
                    <VoiceContactAvatar contact={contact} />
                    <div className="calling-animation">
                      {!callActive ? (
                        <CallingAnimation />
                      ) : (
                        <Timer time={time} />
                      )}
                    </div>

                    {/* <AnimatePresence>
                    {isPaused && (
                      <motion.div
                        css={{
                          position: "absolute",
                          top: "calc(100% + 0.75em)",
                        }}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{
                          opacity: 0,
                          height: 0,
                          transition: overlayTransition,
                        }}
                        transition={{ duration: 0.75, ease: ease }}
                      >
                        Your call is currently on hold...
                      </motion.div>
                    )}
                  </AnimatePresence> */}
                    <OnHoldIndicator onHold={isPaused} />
                  </div>
                  {callActive && (
                    <motion.button
                      className="voice-bot-button"
                      style={{
                        cursor: callActive ? "pointer" : "not-allowed",
                      }}
                      animate={{
                        top: setTop,
                        opacity: callActive ? 1 : 0.5,
                      }}
                      onClick={handleVoiceBotClick}
                      disabled={!callActive}
                    >
                      <img src={VoiceBotIcon} alt="Active Voice Bot" />
                    </motion.button>
                  )}
                  <div style={{ paddingBottom: "40px" }}>
                    <ButtonBar
                      callActive={callActive}
                      callMuted={callMuted}
                      muteCall={muteCall}
                      endCall={endCall}
                      showNotImplemented={showNotImplemented}
                      isFullScreen={isFullScreen}
                    />
                  </div>
                </div>
                {voiceBotActive && (
                  <Suspense>
                    <VoiceBotOverlay
                      transcribedText={transcribedText}
                      voiceBotActive={voiceBotActive}
                      handleVoiceBotClicked={handleVoiceBotClick}
                    />
                  </Suspense>
                )}
              </div>
            </>
          )}
        </DraggableOverlay>
      )}
    </AnimatePresence>
  );
}
