import { useMemo } from "react";
import { useSnapshot } from "valtio";
import { callsState } from "../calls/callState";

export function useUnreadCallsCount() {
  const { calls } = useSnapshot(callsState);

  return useMemo(() => {
    return calls
      .values()
      .filter((c) => !c.isRead)
      .count();
  }, [calls]);
}
